export const MESSAGES = {
  success: {
    fileDeleted: {
      english: 'File Deleted',
      german: 'Datei gelöscht'
    },
    fileUploaded: {
      english: 'Successfully uploaded',
      german: 'Erfolgreich hochgeladen'
    },
    sessionBooked: {
      english: 'Session booked successfully',
      german: 'Session erfolgreich gebucht'
    },
    passwordChanged: {
      english: 'Password changed successfully',
      german: 'Passwort erfolgreich geändert'
    },
    passwordCreated: {
      english: 'Password created successfully',
      german: 'Passwort erfolgreich erstellt'
    },
    resetLinkSent: {
      english: 'Reset Link has been sent to the mail',
      german: 'Link zum Zurücksetzen erfolgreich an gesendet'
    },
    timeSlotConfirmed: {
      english: 'Time slot confirmed',
      german: 'Zeitfenster bestätigt'
    },
    userRegistered: {
      english: 'User registered successfully',
      german: 'Benutzer erfolgreich registriert'
    },
    successfullySubmited: {
      english: 'Successfully submited',
      german: 'Erfolgreich übermittelt'
    },
    actionItemsSaved: {
      english: 'Action items saved successfully',
      german: 'Action Items erfolgreich gespeichert'
    },
    keyTakeawaysSaved: {
      english: 'Key Takeaways saved successfully',
      german: 'Key Takeaways erfolgreich gespeichert'
    },
    surveySubmitted: {
      english: 'Feedback submitted',
      german: 'Feedback übermittelt'
    },
    notesSaved: {
      english: 'Notes Saved Successfully',
      german: 'Notizen erfolgreich gespeichert'
    },
    nextSessionBooked: {
      english: 'Next session booked successfully',
      german: 'Nächste Session erfolgreich gebucht'
    },
    journeyEnded: {
      english: 'Journey ended successfully',
      german: 'Journey erfolgreich abgeschlossen'
    },
    profileUpdated: {
      english: 'Profile updated successfully',
      german: 'Profil erfolgreich aktualisiert'
    },
    profilePasswordUpdated: {
      english: 'Profile password updated successfully',
      german: 'Profilpasswort erfolgreich aktualisiert'
    },
    preparationQuestionsSaved: {
      english: 'Successfully saved session preparation questions and answers',
      german: 'Erfolgreich gespeicherte Vorbereitungsfragen und Antworten'
    },
  },


  error: {
    fileDeleteFailed: {
      english: 'Failed To Delete',
      german: 'Löschen fehlgeschlagen'
    },
    tokenNotValid: {
      english: 'Token is not valid',
      german: 'Token ist ungültig'
    },
    somethingWentWrong: {
      english: 'Something went wrong',
      german: 'Etwas ist schiefgelaufen'
    },
    emailIdNotExist: {
      english: 'Email id does not exist. Please provide the correct email id',
      german: 'Die Mailadresse existiert nicht.'
    },
    selectOneTimeOrRejectAll: {
      english: 'Please select one time or reject all',
      german: 'Bitte wähle eine Zeit oder wähle alle ab'
    },
    linkNotValid: {
      english: 'Link is not valid',
      german: 'Link ist ungültig'
    },
    fillBothTimeSlots: {
      english: 'Please fill both time slots',
      german: 'Bitte beide Zeitfenster ausfüllen'
    },
    validFile: {
      english: 'Please select valid file',
      german: 'Bitte gültige Datei auswählen'
    },
    noNotesFound: {
      english: 'No Notes found',
      german: 'Keine Notizen gefunden'
    },
    dateFieldNecessary: {
      english: 'Date field is necessary',
      german: 'Datumsangabe ist notwendig'
    },
    bookingAllowedInAdvance: {
      english: 'Booking is allowed at least 48 hours in advance.',
      german: 'Die Buchung ist mindestens 48 Stunden im Voraus möglich.'
    },
    preparationQuestionsError: {
      english: 'An error occurred while saving preparation questions and answers',
      german: 'Ein Fehler ist aufgetreten beim Speichern der Vorbereitungsfragen und -antworten'
    },
  }

};
